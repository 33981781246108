<template>
	<div id="course-preview-wrapper" :class="{ preview: preview }">
		<div class="content-list">
			<div class="content-item" v-for="item in toc">
				<div v-if="!item.drip" class="item-icon"><Icon class="icon-svg" name="play" :scale="0.5" /></div>
				<div v-else class="item-icon"><Icon class="icon-svg" name="lock-solid" :scale="0.5" /></div>
				<div class="item-title">{{ item.title }}</div>
			</div>
			<div class="content-item" v-if="tocCount > 3">
				<div class="item-icon"><Icon class="icon-svg" name="lock-solid" :scale="0.5" /></div>
				<div class="item-title">+ {{ tocCount - 3 }} More</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Icon from './icons/Icon.vue'

	export default {
		name: 'CoursePreview',
		components: {
			Icon,
		},
		props: {
			username: {
				type: String,
				default: '',
			},
			isDemo: Boolean,
			primaryColor: { type: String, default: '#5383ff' },
			preview: { type: Boolean },
			product: { type: Object },
		},
		data() {
			return {
				loading: false,
			}
		},
		computed: {
			toc() {
				return this.product.course.toc.slice(0, 3)
			},
			tocCount() {
				return this.product.course.toc.length
			},
		},
		async mounted() {
			const bodyStyles = document.body.style
			this.$emit('initialized')
		},
		methods: {},
	}
</script>
<style lang="scss" scoped>
	.preview {
		padding: 20px;
		.content-list {
			display: flex;
			flex-direction: column;
			gap: 15px;
			overflow: visible;

			.content-item {
				display: flex;
				overflow: visible;
				.item-icon {
					min-width: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #323232;
					border-radius: 50%;
					width: 24px;
					height: 24px;
					aspect-ratio: 1;
					margin-right: 10px;
					filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));

					.svg-icon {
						transform: scale(0.5);
					}
				}
				.item-title {
					font-family: 'Inter';
					font-style: normal;
					font-weight: 600;
					font-size: 13px;
					line-height: 185%;
					letter-spacing: -0.01em;
					color: #323232;
					text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
					overflow: visible;
				}
			}
		}
	}
</style>
