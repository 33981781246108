<template>
	<div :class="['load-wrapper', { 'has-mask': mask }]" :style="wrapperStyles">
		<div class="blue-circle">
			<div class="spinner-border" role="status">
				<span class="sr-only">{{ $t('Loading...') }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			mask: {
				type: Boolean,
				default: false,
			},
			wrapperStyles: {
				type: Object,
			},
		},
	}
</script>

<style lang="scss">
	.load-wrapper {
		z-index: 12;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		.blue-circle {
			align-items: center;
			justify-content: center;
			background: var(--stan-primary-primary-color);
			width: 46px;
			height: 46px;
			text-align: center;
			color: var(--stan-white);
			border-radius: 50%;
			display: flex;
		}
		&.has-mask {
			.blue-circle {
				margin-left: -23px;
				top: 50%;
				margin-top: -23px;
				position: absolute;
				transform: none;
			}
		}
	}

	.load-wrapper:before {
		content: none;
	}

	.load-wrapper.has-mask:before {
		border-radius: 10px;
		background: var(--stan-white);
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		left: 0;
	}
</style>
