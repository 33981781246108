<template>
	<div id="webinar__element" class="calendar" :class="{ preview: preview }">
		<div v-if="hasAvailableSession" class="calendar__day-slots">
			<div class="w-100 text-center calendar__title">{{ calendarTitle }}</div>
			<div class="w-100 text-center calendar__timezone">{{ timezone }}</div>
			<div class="d-flex calendar__time-slots" :style="isDemo ? 'padding-left: 16px; padding-right: 16px;' : ''">
				<div v-for="(slot, index) in currentSessions" v-if="!isLoading" class="calendar__time-slot">
					<div
						class="calendar__time-slot-button"
						:class="{
							selected: !isDemo
								? activeTime !== null && activeTime.booking_id === slot.booking_id
								: activeTime !== null && activeTime.date === slot.date,
							disabled: slot.available_seats < 1,
							invisible: slot.placeholder,
						}"
						@click="selectTime(slot)"
					>
						<div class="calendar__time" v-if="!slot.placeholder">
							<div>{{ slot.date }}</div>
							<div>{{ slot.stime }} - {{ slot.end_time }}</div>
						</div>
						<div class="calendar__time" v-else>-</div>
					</div>
				</div>
				<div
					v-if="isLoading"
					class="w-100 d-flex calendar__loader align-items-center justify-content-center"
					style="transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms"
				>
					<HollowDotsSpinner :animation-duration="1000" :dot-size="12" :dots-num="3" :color="primaryColor" />
				</div>
			</div>
			<div class="d-flex mb-2">
				<div v-if="hasPrevSessions || hasNextSessions" class="session-button-container">
					<div class="sessions-button" :class="{ disabled: !hasPrevSessions }" @click="navigateSessions('prev')">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.95 11" xmlns:xlink="http://www.w3.org/1999/xlink">
							<path d="M7.05852 9.5L2.94138 5L7.05852 0.5" fill="none" stroke-width="1"></path>
						</svg>
					</div>
					<div class="sessions-button next" :class="{ disabled: !hasNextSessions }" @click="navigateSessions('next')">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.95 11" xmlns:xlink="http://www.w3.org/1999/xlink">
							<path d="M7.05852 9.5L2.94138 5L7.05852 0.5" fill="none" stroke-width="1"></path>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center no-upcoming-label">Currently, there are no upcoming events. Please check back later!</div>
	</div>
</template>
<script>
	import moment from 'moment'
	import HollowDotsSpinner from './HollowDotsSpinner'
	import { unsupportedTimezoneMap } from './utils'

	export default {
		name: 'WebinarCalendar',
		components: {
			HollowDotsSpinner,
		},
		props: {
			slug: { type: String, default: '' },
			username: {
				type: String,
				default: '',
			},
			isDemo: Boolean,
			primaryColor: { type: String, default: '#5383ff' },
			apiBaseUrl: { type: String, default: process.env.NUXT_ENV_PYTHON_API },
			duration: { type: Number, default: 0 },
			sessionsPerPage: { type: Number, default: 3 },
			previewDemoSessions: {
				type: Array,
				default: () => [],
			},
			preview: { type: Boolean },
			preSelectedSession: { type: String },
		},
		emits: ['time-selected'],
		data() {
			return {
				loading: false,
				firstLoad: null,
				activeTime: null,
				availableSessions: [],
				sessionDisplayIndex: 0,
				demoSessions: [],
			}
		},
		computed: {
			isLoading() {
				return this.loading
			},
			currentDate() {
				return moment()
			},
			timezone() {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				return unsupportedTimezoneMap[timezone] || timezone
			},
			currentSessions() {
				const result = this.sessions.slice(this.sessionDisplayIndex, this.sessionDisplayIndex + this.sessionsPerPage)
				while (result.length < Math.min(3, this.sessions.length)) {
					result.push({ placeholder: true })
				}
				return result
			},
			hasNextSessions() {
				return this.sessionDisplayIndex + this.sessionsPerPage < this.sessions.length
			},
			hasPrevSessions() {
				return this.sessionDisplayIndex - this.sessionsPerPage >= 0
			},
			sessions() {
				if (!this.isDemo) {
					return this.availableSessions
				} else {
					return this.demoSessions
				}
			},
			calendarTitle() {
				return this.preview ? 'Select a Slot' : 'Choose Your Slot'
			},
			hasAvailableSession() {
				return this.sessions.length > 0 && this.sessions.find(slot => slot.available_seats > 0)
			},
		},
		async mounted() {
			const bodyStyles = document.body.style
			await this.reset()
		},
		watch: {
			previewDemoSessions() {
				this.demoSessions = this.previewDemoSessions.map(sesh => {
					return {
						date: sesh.date,
						stime: sesh.stime,
						end_time: sesh.end_time,
					}
				})
			},
		},
		methods: {
			async reset() {
				this.availableSessions = []
				if (!this.isDemo) {
					this.loading = true
					await this.getAvailableTimeSlots()
				} else {
					if (!this.previewDemoSessions) {
						Date.prototype.addDays = function (days) {
							var date = new Date(this.valueOf())
							date.setDate(date.getDate() + days)

							return date
						}
						const dates = [new Date(), new Date().addDays(7), new Date().addDays(14)]
						var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
						this.demoSessions = dates.map(d => {
							return {
								date: d.toLocaleDateString('en-US', options),
								stime: '10:00',
								end_time: '10:30AM',
							}
						})
					} else {
						this.demoSessions = this.previewDemoSessions.map(sesh => {
							return {
								date: sesh.date,
								stime: sesh.stime,
								end_time: sesh.end_time,
							}
						})
					}
				}
				this.activeTime = this.activeTime || null

				this.$emit('initialized')
			},
			async getAvailableTimeSlots() {
				const params = {
					timezone: this.timezone,
				}
				try {
					const resp = await this.$axios.get(`v1/booking/sessions/${this.username}/${this.slug}`, {
						params,
						baseURL: this.apiBaseUrl,
					})

					this.availableSessions = resp.data.available_slots
				} catch (err) {
					console.error(err)
					this.availableSessions = []
				}
				this.loading = false
				if (!this.preview && this.sessions.length === 1 && this.sessions[0].available_seats > 0) {
					this.selectTime(this.sessions[0])
				}
				if (this.preSelectedSession) {
					const selected = this.sessions.find(s => s.startDateTime === this.preSelectedSession)
					if (selected) {
						const selectedIndex = this.sessions.indexOf(selected)
						this.sessionDisplayIndex = Math.floor(selectedIndex / 3) * 3
						this.selectTime(selected)
						document.getElementById('webinar__element').scrollIntoView({ behavior: 'smooth' })
					}
				}
				return true
			},
			selectTime(slot) {
				this.activeTime = slot
				this.$emit('time-selected', this.activeTime)
			},
			navigateSessions(direction) {
				if (direction == 'next') {
					if (this.sessionDisplayIndex + this.sessionsPerPage < this.sessions.length) {
						this.sessionDisplayIndex += this.sessionsPerPage
					}
				} else {
					if (this.sessionDisplayIndex - this.sessionsPerPage >= 0) {
						this.sessionDisplayIndex -= this.sessionsPerPage
					}
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.w-100 {
		width: 100% !important;
	}

	.text-center {
		text-align: center !important;
	}

	.calendar__loader {
		padding: 125.5px 0;
	}
	.calendar__day-slots {
		:disabled {
			cursor: default;
			opacity: 0.5;
			pointer-events: none;
		}
		.calendar_days span {
			font-size: 12px;
			margin-bottom: 10px;
			text-transform: uppercase;
			color: #404243;
			font-weight: 500;
			width: 40px;
			text-align: center;
		}
		.calendar_date span {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			min-width: 40px;
			background: rgb(92 124 255/0.1);
			color: #5c7cff;
			cursor: pointer;
			font-weight: 700;
			cursor: not-allowed;
			font-size: 20px;
		}
		.calendar_date span button {
			color: #5c7cff;
			color: var(--stan-store-theme-primary-color);
			width: 100%;
			height: 100%;
		}

		.selected {
			// background: #5c7cff !important;
			background: var(--stan-store-theme-primary-color) !important;
			box-shadow: 0 5px 25px -5px rgb(83 116 255 / 50%);
		}
		.selected button {
			color: #fff !important;
		}
	}

	.disabled {
		opacity: 0.5;
		pointer-events: none;
		cursor: none;
	}

	.calendar__time-slots {
		padding: 0 15px;
		flex-wrap: wrap;
		.calendar__time-slot {
			width: 100%;
			float: left;
			.calendar__time-slot-button {
				display: flex;
				height: 46px;
				width: 100%;
				border-radius: 30px;
				background: rgb(92 124 255/0.1);
				color: var(--stan-store-theme-primary-color);
				margin: 11px 0;
				position: relative;
				float: left;
				cursor: pointer;
				font-weight: 500;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
			.calendar__time {
				font-size: 14px;
				line-height: 16px;
				display: inline-block;
			}

			.ampam {
				font-size: 14px;
				font-weight: 500;
				margin-left: 3px;
				display: inline-block;
			}
			.d__flex {
				display: flex;
			}
			.selected {
				// background: #5c7cff !important;
				background: var(--stan-store-theme-primary-color) !important;
				box-shadow: 0 5px 25px -5px rgb(83 116 255 / 50%);
			}
			.selected span {
				color: #fff !important;
			}
		}
	}

	.no-upcoming-label {
		text-align: center;
		font-size: 14px;
		padding: 20px;
		padding-top: 0;
	}

	.orient-horizontal {
		.calendar__day-slots {
			.calendar_date span {
				background-color: #ffffff;
			}

			.selected {
				box-shadow: none;
			}
		}

		.calendar__time-slots {
			height: auto;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			padding: 10px 0;
			gap: 15px 40px;
			letter-spacing: 0;
			.calendar__time-slot {
				.calendar__time-slot-button {
					display: flex;
					border-radius: 0;
					padding: 15px 10px;
					height: auto;
					background-color: #edf2ff;
					margin: 0;
					color: rgba(0, 0, 0, 0.87);
					.ampm {
						display: block;
						margin: 0;
					}
					.calendar__time {
						display: flex;
						width: 100%;
						justify-content: space-between;
						margin-left: 10px;
						margin-right: 10px;
					}

					&:hover,
					&.selected {
						color: #ffffff;
						background-color: var(--stan-store-theme-primary-color);
						box-shadow: none;
					}

					&.invisible {
						padding: 15px 10px;
						visibility: hidden !important;
					}
				}
			}
		}
	}
	.session-button-container {
		margin: auto;
		display: flex;
		column-gap: 20px;

		.sessions-button {
			background: var(--stan-store-theme-derived2-color);
			padding: 6px;
			border-radius: 50%;
			line-height: 1px;
			aspect-ratio: 1;
			&:hover {
				cursor: pointer;
			}

			svg {
				path {
					stroke: var(--stan-store-theme-primary-color, #5c7cff);
				}
				width: 16px;
				height: 16px;
			}

			&.next {
				transform: scale(-1, 1);
			}
		}
	}

	.preview {
		padding-top: 20px !important;
		padding: 0;

		.calendar__title {
			display: block !important;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 600;
			font-size: 13px;
			line-height: 120%;
			text-align: center;
			letter-spacing: -0.01em;
			color: #323232;
			text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
			overflow: visible;
		}

		.calendar__timezone {
			display: none !important;
			font-style: normal;
		}
		.calendar__time-slots {
			width: 100%;
			flex-wrap: wrap;
			padding: 0;
			overflow: visible;
			display: flex;
			gap: 15px;
			padding: 20px;

			.calendar__time-slot {
				width: 100%;
				display: flex;
				overflow: visible;

				.calendar__time-slot-button {
					display: flex;
					height: 50px;
					width: 100%;
					box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
					border-radius: 15px;
					border: 1px solid #323232;
					background: transparent !important;
					// color: var(--stan-store-theme-primary-color);
					color: black;
					margin: 0;
					row-gap: 15px;
					overflow: visible;
					position: relative;
					float: left;
					cursor: pointer;

					align-items: center;
					justify-content: center;
					text-align: center;

					&.invisible {
						padding: 15px 10px;
						visibility: hidden !important;
					}
				}
				.calendar__time {
					width: 100%;
					display: flex;
					justify-content: space-between;
					padding-left: 20px;
					padding-right: 20px;
					font-family: 'Inter';
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					line-height: 120%;
					letter-spacing: -0.01em;
					text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
					overflow: visible;
					div {
						overflow: visible;
					}
					div:nth-child(2) {
						font-weight: 400;
					}
				}
				.ampam {
					font-size: 14px;
					font-weight: 500;
					margin-left: 3px;
					display: inline-block;
				}
				.d__flex {
					display: flex;
				}
				.selected {
					background: #323232 !important;
					color: #ffffff !important;
					span {
						color: #ffffff !important;
					}
				}
			}
		}

		.orient-horizontal {
			.calendar__day-slots {
				.calendar_date span {
					background-color: #ffffff;
				}

				.selected {
					box-shadow: none;
				}
			}

			.calendar__time-slots {
				height: auto;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				padding: 10px 0;
				gap: 15px 40px;
				letter-spacing: 0;
				.calendar__time-slot {
					.calendar__time-slot-button {
						display: flex;
						border-radius: 0;
						padding: 15px 10px;
						height: auto;
						background-color: #edf2ff;
						margin: 0;
						color: rgba(0, 0, 0, 0.87);
						.ampm {
							display: block;
							margin: 0;
						}
						.calendar__time {
							display: flex;
							justify-content: space-between;
							margin-left: 10px;
							margin-right: 10px;
						}

						&:hover,
						&.selected {
							color: #ffffff;
							background-color: var(--stan-store-theme-primary-color);
							box-shadow: none;
						}

						&.invisible {
							padding: 15px 10px;
							visibility: hidden !important;
						}
					}
				}
			}
		}
		.session-button-container {
			margin: auto;
			display: flex;
			column-gap: 20px;

			.sessions-button {
				background: var(--stan-store-theme-derived2-color);
				padding: 6px;
				border-radius: 50%;
				line-height: 1px;
				aspect-ratio: 1;
				&:hover {
					cursor: pointer;
				}

				svg {
					path {
						// stroke: var(--stan-store-theme-primary-color, #5c7cff);
						stroke: #323232;
					}
					width: 16px;
					height: 16px;
				}

				&.next {
					transform: scale(-1, 1);
				}
			}
		}
	}
</style>
