<template>
	<div class="media-preview">
		<div class="media-preview-container">
			<div v-if="!urlError && embedUrl" class="media-preview-content">
				<iframe
					ref="embedIframe"
					:src="embedUrl"
					frameborder="0"
					:style="embedStyle"
					:class="embedType"
					allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
					loading="lazy"
				></iframe>
			</div>
			<div v-else class="media-preview-content" :class="[embedType]">
				<div class="default-outer">
					<div class="default-text-container">
						<img src="/images/media-preview-default.svg" class="default-icon" />
						<p class="default-text">Your media content will be displayed here</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import SocialIcons from './SocialIcons'
	import ImagePreview from './ImagePreview'
	export default {
		name: 'MediaPreview',
		components: {
			SocialIcons,
			ImagePreview,
		},

		props: {
			embedUrl: { type: String },
			urlError: {
				type: Boolean,
				default: false,
			},
			embedType: {
				type: String,
			},
			embedStyle: {
				type: Object,
				default: () => ({}),
			},
		},
	}
</script>

<style scoped lang="scss">
	.media-preview-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
		align-self: stretch;
		border-radius: 10px;
		width: 100%;
	}
	.media-preview {
		height: 100%;
		width: 100%;
		margin-bottom: 12px !important;
		position: relative;
	}
	.media-preview-content :first-child {
		width: 100%;
		border: none;
		border-radius: 10px;
		box-sizing: border-box;
		flex: 1 1 auto;
	}
	.media-preview-content {
		line-height: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.podcast-apple.lg {
		height: 450px;
	}
	.podcast-apple.sm {
		height: 200px;
	}
	.default-outer {
		color: var(--stan-text-light-color);
		background-color: var(--stan-gray-light-color);
		display: flex;
		height: 160px;
		padding: 16px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		align-self: stretch;
		.default-text-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			.default-text {
				color: var(--Text-Light, #808eb6);
				text-align: center;
				font-family: Inter;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: -0.26px;
				width: 158px;
			}
			.default-icon {
				width: 40px;
				height: 40px;
			}
		}
	}
</style>
