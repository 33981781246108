import LoadingSpinner from '../LoadingSpinner.vue'

export default {
	data: () => ({
		isLoading: false,
		debounceTimeout: null,
	}),

	methods: {
		handleLoading(isLoading) {
			if (isLoading) {
				this.isLoading = true

				if (this.debounceTimeout) {
					clearTimeout(this.debounceTimeout)
					this.debounceTimeout = null
				}
			} else {
				if (this.debounceTimeout) {
					clearTimeout(this.debounceTimeout)
				}

				this.debounceTimeout = setTimeout(() => {
					this.isLoading = false
					this.debounceTimeout = null
				}, 250)
			}
		},
	},

	components: {
		LoadingSpinner,
	},
}
