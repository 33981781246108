<template>
	<div id="digital-download-preview-wrapper" :class="{ preview: preview }">
		<div class="content-list">
			<div class="content-item">
				<div class="item-icon"><Icon class="icon-svg" name="digital-download" :scale="0.5" /></div>
				<div class="item-title">{{ fileName }}</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Icon from './icons/Icon.vue'

	export default {
		name: 'DigitalDownloadPreview',
		components: {
			Icon,
		},
		props: {
			username: {
				type: String,
				default: '',
			},
			isDemo: Boolean,
			primaryColor: { type: String, default: '#5383ff' },
			preview: { type: Boolean },
			product: { type: Object },
		},
		data() {
			return {
				loading: false,
				fileNames: [],
				extDerivedFileNames: {
					jpg: 'JPG Image',
					jpeg: 'JPEG Image',
					png: 'PNG Image',
					xlsx: 'XLSX Spreadsheet',
					docx: 'DOCX Document',
					pdf: 'PDF Document',
					zip: 'ZIP Compressed File',
					default: 'Ready To Download',
				},
			}
		},
		computed: {
			fileName() {
				var list = this.product.digital_download.digital_assets.map(d => d.name)
				if (list.length > 1) {
					return `${list.length} Files Included`
				} else if (list.length === 1) {
					return this.computeFileName(list[0])
				} else {
					return this.extDerivedFileNames['default']
				}
			},
		},
		async mounted() {
			const bodyStyles = document.body.style
			this.$emit('initialized')
		},
		methods: {
			computeFileName(file) {
				const ext = file.slice(file.lastIndexOf('.') + 1)
				if (ext && this.extDerivedFileNames[ext]) {
					return this.extDerivedFileNames[ext]
				} else {
					return this.extDerivedFileNames['default']
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.preview {
		padding: 20px;
		.content-list {
			display: flex;
			flex-direction: column;
			gap: 15px;
			overflow: visible;

			.content-item {
				display: flex;
				overflow: visible;
				.item-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #323232;
					border-radius: 50%;
					width: 24px;
					height: 24px;
					aspect-ratio: 1;
					margin-right: 10px;
					filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));

					.svg-icon {
						transform: scale(0.5);
					}
				}
				.item-title {
					font-family: 'Inter';
					font-style: normal;
					font-weight: 600;
					font-size: 13px;
					line-height: 185%;
					letter-spacing: -0.01em;
					color: #323232;
					text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
					overflow: visible;
				}
			}
		}
	}
</style>
