<template>
	<div class="masonry__grid">
		<div class="masonry__column" v-for="(col, index) in columns" :key="index" :data-column="index" ref="column">
			<template v-for="page in col">
				<slot :page="page" />
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MasonaryWall',
		props: {
			items: {
				type: Array,
				required: true,
			},
		},
		data() {
			return {
				columns: [],
				curItemIndex: 0,
				hasItems: false,
			}
		},
		computed: {},
		mounted() {
			// Mobile is one column. Tablet / desktop is two column
			this.resize = () => {
				if (window.innerWidth >= 768 && this.columns.length != 2) {
					this.columns = [[], []]
					this.reCalculate()
				} else if (window.innerWidth < 768 && this.columns.length != 1) {
					this.columns = [[]]
					this.reCalculate()
				}
			}
			this.resize()
			window.addEventListener('resize', this.resize)
		},
		destroyed() {},
		methods: {
			reCalculate() {
				this.curItemIndex = 0
				this.hasItems = true

				// Only 1 item only show 1 column
				if (this.items.length < 2) {
					this.columns = [[]]
				}

				this.fillGrid()
			},

			fillGrid() {
				if (!this.hasItems) return

				// Keep filling until no more items
				this.$nextTick(() => {
					let shortestCol = 0
					this.$refs.column.forEach((col, i) => {
						const curShortestColHeight = (this.$refs.column[shortestCol]?.clientHeight || 0) - 30
						shortestCol = (col.clientHeight || 0) < curShortestColHeight ? i : shortestCol
					})
					const shortestColumn = this.$refs.column[shortestCol]
					if (shortestColumn) {
						this.getAddToColumn(shortestColumn.dataset.column)
						this.fillGrid()
					}
				})
			},

			getAddToColumn(index) {
				const column = this.columns[index]
				if (this.items[this.curItemIndex]) {
					column.push(this.items[this.curItemIndex])
					this.curItemIndex++
				} else {
					this.hasItems = false
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.masonry__grid {
		display: flex;
		justify-content: center;
		column-gap: 30px;
		overflow: visible;
		max-width: 800px;
		margin: auto;
	}
	.masonry__column {
		min-width: 0;
		flex: 1 1 0px;
		height: fit-content;
		max-width: 400px;
		overflow: visible;
	}
</style>
