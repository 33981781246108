<template>
	<div class="product-price-off" v-if="offValue && amount && saleAmount">
		<span>{{ offValue }}</span>
		<span>OFF</span>
	</div>
</template>
<script>
	const OFF_TYPE_PERCENT = 'percent'
	const OFF_TYPE_ABSOLUTE = 'absolute'
	export default {
		props: {
			saleAmount: { type: Number, default: undefined },
			amount: { type: Number, default: undefined },
			saleType: {
				type: String,
				default: OFF_TYPE_PERCENT,
				validator: val => !val || [OFF_TYPE_PERCENT, OFF_TYPE_ABSOLUTE].indexOf(val) > -1,
			},
		},
		computed: {
			offValue() {
				const off = this.saleType === OFF_TYPE_ABSOLUTE ? this.absoluteOff() : this.percentOff()
				return off ? off : undefined
			},
		},
		methods: {
			percentOff() {
				const off = ((this.amount - this.saleAmount) * 100) / this.amount
				return off ? off.toFixed(0) + '%' : undefined
			},
			absoluteOff() {
				const off = this.amount - this.saleAmount
				return off || undefined
			},
		},
	}
</script>
